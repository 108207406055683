import * as Constants from "./singapore/constants";

export default {
  ...Constants,
  twoDigitCode: {
    singapore: "SG",
  },
  cities: [
    {
      value: "singapore",
      code: "singapore",
    },
  ],
  PREFERRED_GENDER: [
    {
      code: "any",
      label: "Any",
    },
    {
      code: "male",
      label: "Male",
    },
    {
      code: "female",
      label: "Female",
    },
  ],
};
