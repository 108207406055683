export const CITY_NAME = "singapore";
export const CURRENCY = "SGD";
export const AREA_UNIT = "sqft";
export const PROPERTY_TYPES = [
  {
    label: "HDB",
    code: "HDB",
  },
  {
    label: "Condominium",
    code: "CONDO",
    subTypes: [
      {
        label: "Condominium",
        code: "CONDO",
      },
      {
        label: "Apartment",
        code: "apartment",
      },
      {
        label: "Executive Condominium",
        code: "executiveCondo",
      },
    ],
  },
  // {
  //   label: "Landed",
  //   code: "LANDED",
  // },
];

export const PROPERTY_STATUS = [
  {
    label: "Under Construction",
    code: "underConstruction",
  },
  {
    label: "Ready To Move",
    code: "readyToMove",
  },
];

export const BUILDING_TYPES = [
  {
    label: "Block",
    code: "block",
  },
  {
    label: "Building",
    code: "building",
  },
];

export const UNIT_TYPE = [
  {
    code: "sale",
    label: "Sale",
  },
  {
    code: "rent",
    label: "Rent",
  },
  {
    code: "room-rental",
    label: "Room rental",
  },
];

export const PRICE_NEGOTIABLE = [
  {
    code: "startingFrom",
    label: "Starting From",
  },
  {
    code: "viewToOffer",
    label: "View to Offer",
  },
  {
    code: "negotiable",
    label: "Negotiable",
  },
  {
    code: "proceOnEnquiry",
    label: "Price On enquiry",
  },
];

export const ROOM_LIST = [
  {
    code: "studio",
    label: "Studio",
  },
  {
    code: "1",
    label: "1 Bedroom",
  },
  {
    code: "2",
    label: "2 Bedroom",
  },
  {
    code: "3",
    label: "3 Bedroom",
  },
  {
    code: "4",
    label: "4 Bedroom",
  },
  {
    code: "5",
    label: "5 Bedroom",
  },
  {
    code: "6",
    label: "6 Bedroom",
  },
  {
    code: "7",
    label: "7 Bedroom",
  },
  {
    code: "8",
    label: "8 Bedroom",
  },
  {
    code: "9",
    label: "9 Bedroom",
  },
  {
    code: "10",
    label: "10+ Bedroom",
  },
];

export const BATHROOM_OPTIONS = [
  {
    code: 0,
    label: "No Bathroom",
  },
  {
    code: 1,
    label: "1 Bathroom",
  },
  {
    code: 2,
    label: "2 Bathroom",
  },
  {
    code: 3,
    label: "3 Bathroom",
  },
  {
    code: 4,
    label: "4 Bathroom",
  },
  {
    code: 5,
    label: "5 Bathroom",
  },
];

export const LEASE_TERM = [
  {
    code: "1",
    label: "1 year",
  },
  {
    code: "2",
    label: "2 Years",
  },
  {
    code: "3",
    label: "3 or more years",
  },
  {
    code: "shortTerm",
    label: "Short Term",
  },
  {
    code: "flexible",
    label: "Flexible",
  },
];

export const FLOOR_LEVEL = [
  {
    code: "ground",
    label: "Ground",
  },
  {
    code: "low",
    label: "Low",
  },
  {
    code: "mid",
    label: "Mid",
  },
  {
    code: "high",
    label: "High",
  },
  {
    code: "penthouse",
    label: "Penthouse",
  },
];

export const HDB_TYPES = [
  {
    code: "Malay",
    label: "1-Room / Studio",
  },
  {
    code: "Chinese",
    label: "2A",
  },
  {
    code: "others",
    label: "2I (Improved)",
  },
];

export const HDB_SELLER_ETHINICITY = [
  {
    code: "Malay",
    label: "Malay",
  },
  {
    code: "Chinese",
    label: "Chinese",
  },
  {
    code: "others",
    label: "India/Other",
  },
];

export const HDB_SELLER_RESIDENCY = [
  {
    code: "Malay",
    label: "Citizen/Malaysian SPR",
  },
  {
    code: "Chinese",
    label: "Non-Malaysian SPR",
  },
];

export const FURNISHING = [
  {
    code: "unfurnished",
    label: "Unfurnished",
  },
  {
    code: "partiallyFurnished",
    label: "Partially Furnished",
  },
  {
    code: "fullyFurnished",
    label: "Fully Furnished",
  },
];

export const PRICE_TYPE = [
  {
    code: "startingFrom",
    label: "Starting From",
  },
  {
    code: "range",
    label: "Range",
  },
  {
    code: "askForPrice",
    label: "Ask For Price",
  },
];

export const DISTRICT = [
  {
    code: "D01",
    label: "D01 Boat Quay / Raffles Place / Marina",
  },
  {
    code: "D02",
    label: "D02 Chinatown / Tanjong Pagar",
  },
  {
    code: "D03",
    label: "D03 Alexandra / Commonwealth",
  },
  {
    code: "D04",
    label: "D04 Harbourfront / Telok Blangah",
  },
  {
    code: "D05",
    label: "D05 Buona Vista / West Coast / Clementi New Town",
  },
  {
    code: "D06",
    label: "D06 City Hall / Clarke Quay",
  },
  {
    code: "D07",
    label: "D07 Beach Road / Bugis / Rochor",
  },
  {
    code: "D08",
    label: "D08 Farrer Park / Serangoon Rd",
  },
  {
    code: "D09",
    label: "D09 Orchard / River Valley",
  },
  {
    code: "D10",
    label: "D10 Tanglin / Holland / Bukit Timah",
  },
  {
    code: "D11",
    label: "D11 Newton / Novena",
  },
  {
    code: "D12",
    label: "D12 Balestier / Toa Payoh",
  },
  {
    code: "D13",
    label: "D13 Macpherson / Potong Pasir",
  },
  {
    code: "D14",
    label: "D14 Eunos / Geylang / Paya Lebar",
  },
  {
    code: "D15",
    label: "D15 East Coast / Marine Parade",
  },
  {
    code: "D16",
    label: "D16 Bedok / Upper East Coast",
  },
  {
    code: "D17",
    label: "D17 Changi Airport / Changi Village",
  },
  {
    code: "D18",
    label: "D18 Pasir Ris / Tampines",
  },
  {
    code: "D19",
    label: "D19 Hougang / Punggol / Sengkang",
  },
  {
    code: "D20",
    label: "D20 Ang Mo Kio / Bishan / Thomson",
  },
  {
    code: "D21",
    label: "D21 Clementi Park / Upper Bukit Timah",
  },
  {
    code: "D22",
    label: "D22 Boon Lay / Jurong / Tuas",
  },
  {
    code: "D23",
    label: "D23 Dairy Farm / Bukit Panjang / Choa Chu Kang",
  },
  {
    code: "D24",
    label: "D24 Lim Chu Kang / Tengah",
  },
  {
    code: "D25",
    label: "D25 Admiralty / Woodlands",
  },
  {
    code: "D26",
    label: "D26 Mandai / Upper Thomson",
  },
  {
    code: "D27",
    label: "D27 Sembawang / Yishun",
  },
  {
    code: "D28",
    label: "D28 Seletar / Yio Chu Kang",
  },
];

export const PROPERTY_TENURE = [
  {
    code: "99years",
    label: "99 Years",
  },
  {
    code: "Freehold",
    label: "Free Hold",
  },
  {
    code: "999years",
    label: "999 Leasehold",
  },
  {
    code: "99yearLeasehold",
    label: "99-year Leasehold",
  },
];

export const PRICE_FILTER_OPTIONS_SALE = [
  { code: "all", label: "Any" },
  { code: 200000, label: "S$ 200,000" },
  { code: 300000, label: "S$ 300,000" },
  { code: 500000, label: "S$ 500,000" },
  { code: 1000000, label: "S$ 1,000,000" },
  { code: 2000000, label: "S$ 2,000,000" },
  { code: 3000000, label: "S$ 3,000,000" },
  { code: 4000000, label: "S$ 4,000,000" },
  { code: 5000000, label: "S$ 5,000,000" },
  { code: 6000000, label: "S$ 6,000,000" },
];

export const SEARCH_SORTING_OPTIONS = [
  { code: "newListings", label: "New Listings" },
  { code: "priceLoToHigh", label: "Price (Lo-Hi)" },
  {
    code: "priceHighToLow",
    label: "Price (Hi-Lo)",
  },
  { code: "bedroom", label: "Bedroom" },
  { code: "bathroom", label: "Bathrooms" },
  {
    code: "squareFeetLowToHigh",
    label: "Square Feet (Lo-Hi)",
  },
  {
    code: "squareFeetHighToLow",
    label: "Square Feet (Hi-Lo)",
  },
];

export const PRICE_FILTER_OPTIONS_RENT = [
  { code: "all", label: "Any" },
  { code: 500, label: "S$500" },
  { code: 1000, label: "S$ 1,000" },
  { code: 1500, label: "S$ 1,500" },
  { code: 2000, label: "S$ 2,000" },
  { code: 2500, label: "S$ 2,500" },
  { code: 3000, label: "S$ 3,000" },
  { code: 4000, label: "S$ 4,000" },
  { code: 5000, label: "S$ 5,000" },
  { code: 7500, label: "S$ 7,500" },
  { code: 10000, label: "S$ 10,000" },
  { code: 15000, label: "S$ 15,000" },
  { code: 20000, label: "S$ 20,000" },
  { code: 30000, label: "S$ 30,000" },
  { code: 40000, label: "S$ 40,000" },
];

export const TRAIN_LINE_COLORS = {
  CR: {
    color: "#9CD306",
    name: "Cross-Island Line",
  },
  NE: {
    color: "#9E27B5",
    name: "North-East Line",
  },
  PTC: {
    color: "#738376",
    name: "Punggol LRT Line",
  },
  CC: {
    color: "#FF9F18",
    name: "Circle Line",
  },
  DT: {
    color: "#0051B7",
    name: "Downtown Line",
  },
  NS: {
    color: "#E32216",
    name: "North-South Line",
  },
  EW: {
    color: "#009437",
    name: "East-West Line",
  },
  TE: {
    color: "#9E5A1C",
    name: "Thomson-East Line",
  },
  CG: {
    color: "",
    name: "",
  },
  STC: {
    color: "#738376",
    name: "Sengkang LRT Line",
  },
  JR: {
    color: "#00ADBC",
    name: "Jurong-Region Line",
  },
  BP: {
    color: "#738376",
    name: "Bukit-Panjang LRT Line",
  },
  SK: {
    color: "#738376",
    name: "Sengkang LRT Line",
  },
};

export const HDB_ESTATE = [
  {
    code: "AMK",
    label: "ANG MO KIO",
  },
  {
    code: "BB",
    label: "BUKIT BATOK",
  },
  {
    code: "BD",
    label: "BEDOK",
  },
  {
    code: "BH",
    label: "BISHAN",
  },
  {
    code: "BM",
    label: "BUKIT MERAH",
  },
  {
    code: "BP",
    label: "BUKIT PANJANG",
  },
  {
    code: "CCK",
    label: "CHOA CHU KANG",
  },
  {
    code: "CL",
    label: "CLEMENTI",
  },
  {
    code: "CT",
    label: "CENTRAL AREA",
  },
  {
    code: "GL",
    label: "GEYLANG",
  },
  {
    code: "HG",
    label: "HOUGANG",
  },
  {
    code: "JE",
    label: "JURONG EAST",
  },
  {
    code: "JW",
    label: "JURONG WEST",
  },
  {
    code: "KWN",
    label: "KALLANG/WHAMPOA",
  },
  {
    code: "MP",
    label: "MARINE PARADE",
  },
  {
    code: "PG",
    label: "PUNGGOL",
  },
  {
    code: "PRC",
    label: "PASIR RIS",
  },
  {
    code: "QT",
    label: "QUEENSTOWN",
  },
  {
    code: "SB",
    label: "SEMBAWANG",
  },
  {
    code: "SGN",
    label: "SERANGOON",
  },
  {
    code: "SK",
    label: "SENGKANG",
  },
  {
    code: "TAP",
    label: "TAMPINES",
  },
  {
    code: "TG",
    label: "TENGAH",
  },
  {
    code: "TP",
    label: "TOA PAYOH",
  },
  {
    code: "WL",
    label: "WOODLANDS",
  },
  {
    code: "YS",
    label: "YISHUN",
  },
];
