import React from "react";
import Link from "next/link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useRouter } from "next/router";
import HomeWorkIcon from '@material-ui/icons/HomeWork';

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
  items: {
    paddingBottom: theme.spacing(2),
  },
}));

export default function AddItemCardBox({
  title,
  description,
  buttonLabel,
  buttonActionUrl,
}) {
  const classes = useStyles();
  const router = useRouter();

  const handleClick = () => {
    router.push({
      pathname: buttonActionUrl,
    });
  };

  return (
    <Grid container align="center">
      <Grid xs={12}>
        <HomeWorkIcon fontSize="large" />
      </Grid>
      <Grid xs={12} className={classes.items}>
        <Typography component="h4" variant="h5">
          {title}
        </Typography>
      </Grid>
      <Grid xs={12} className={classes.items}>
        <Typography color="textSecondary" component="p">
          {description}
        </Typography>
      </Grid>

      <Grid xs={12} className={classes.items}>
        <Button variant="contained" color="primary" onClick={handleClick}>
          {buttonLabel}
        </Button>
      </Grid>
    </Grid>
  );
}
