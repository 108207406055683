import PoolSharpIcon from "@material-ui/icons/PoolSharp";
import OutdoorGrillSharpIcon from "@material-ui/icons/OutdoorGrillSharp";
import SportsTennisSharpIcon from "@material-ui/icons/SportsTennisSharp";
import SecuritySharpIcon from "@material-ui/icons/SecuritySharp";
import { twoDigit } from "../../libs/util";

export const Amenities = [
  {
    code: "barbequeArea",
    label: "Barbeque Area",
    Icon: <OutdoorGrillSharpIcon color="secondary" />,
  },
  { code: "clubhouse", label: "Clubhouse", Icon: null },
  { code: "fitnessCorner", label: "Fitness corner", Icon: null },
  { code: "gymnasiumRoom", label: "Gymnasium room", Icon: null },
  { code: "carPark", label: "Car Park", Icon: null },
  { code: "ChildrenPlayground", label: "Children's Playground", Icon: null },
  { code: "functionRoom", label: "Function Room", Icon: null },
  { code: "gameRoom", label: "Game Room", Icon: null },
  { code: "jacuzzi", label: "Jacuzzi", Icon: null },
  { code: "lapPool", label: "Lap Pool", Icon: null },
  { code: "sauna", label: "Sauna", Icon: null },
  { code: "multiPurposeHall", label: "Multi-purpose hall", Icon: null },
  { code: "playground", label: "Playground", Icon: null },
  { code: "pantry", label: "Pantry", Icon: null },
  {
    code: "24HoursSecurity",
    label: "24 Hours Security",
    Icon: <SecuritySharpIcon color="secondary" />,
  },
  {
    code: "swimingPool",
    label: "Swimming Pool",
    Icon: <PoolSharpIcon color="secondary" />,
  },
  { code: "aeroyogaRoom", label: "Aeroyoga Room", Icon: null },
  { code: "wadingRoom", label: "Wading Room", Icon: null },
  {
    code: "tennisCourts",
    label: "Tennis courts",
    Icon: <SportsTennisSharpIcon color="secondary" />,
  },
  {
    code: "reflexologyPath",
    label: "Reflexology Path",
    Icon: null,
  },
  {
    code: "lounge",
    label: "Lounge",
    Icon: null,
  },
  {
    code: "parking",
    label: "Parking",
    Icon: null,
  },
  {
    code: "poolDeck",
    label: "Pool Deck",
    Icon: null,
  },
  {
    code: "security",
    label: "Security",
    Icon: null,
  },
  {
    code: "spaPavillion",
    label: "Spa Pavillion",
    Icon: null,
  },
  {
    code: "waterFeature",
    label: "Water Feature",
    Icon: null,
  },
];

export const ListingAmenities = [
  { code: "airConditioning", label: "Air Conditioning", Icon: null },
  {
    code: "energyEfficientAppliances",
    label: "Energy Efficient Appliances",
    Icon: null,
  },
  { code: "patioORBalconySpace", label: "Patio O R Balcony Space", Icon: null },
  { code: "hardwoodFloors", label: "Hardwood Floors", Icon: null },
  {
    code: "largeWindowsWithNaturalLight",
    label: "Large Windows With Natural Light",
    Icon: null,
  },
  { code: "secureParking", label: "Secure Parking", Icon: null },
  { code: "cityView", label: "City View", Icon: null },
  { code: "smartLocks", label: "Smart Locks", Icon: null },
  { code: "lowFloor", label: "Low Floor", Icon: null },
  { code: "waterHeater", label: "Water Heater", Icon: null },
  { code: "poolView", label: "Pool View", Icon: null },
  { code: "dishwasher", label: "Dishwasher", Icon: null },
  { code: "midFloor", label: "Mid Floor", Icon: null },
  { code: "petFriendly", label: "Pet Friendly", Icon: null },
  { code: "bombshelter", label: "Bombshelter", Icon: null },
  { code: "cornerUnit", label: "Corner Unit", Icon: null },
  { code: "highFloor", label: "High Floor", Icon: null },
  {
    code: "highSpeedInternetAccess",
    label: "High Speed Internet Access",
    Icon: null,
  },
  { code: "balcony", label: "Balcony", Icon: null },
  { code: "outdoorPatio", label: "Outdoor Patio", Icon: null },
  { code: "intercom", label: "Intercom", Icon: null },
  { code: "cookerHobHood", label: "Cooker Hob Hood", Icon: null },
  { code: "originalCondition", label: "Original Condition", Icon: null },
  { code: "parkGreeneryView", label: "Park Greenery View", Icon: null },
  { code: "fridge", label: "Fridge", Icon: null },
  { code: "internetConnection", label: "Internet Connection", Icon: null },
  { code: "ovenMicrowave", label: "Oven Microwave", Icon: null },
  { code: "bathRoom", label: "Bath Room", Icon: null },
  { code: "columnFree", label: "Column Free", Icon: null },
  { code: "restRoom", label: "Rest Room", Icon: null },
  { code: "24HoursSecurity", label: "24 Hours Security", Icon: null },
  { code: "gameRoom", label: "Game Room", Icon: null },
  { code: "playground", label: "Playground", Icon: null },
  { code: "aerobicPool", label: "Aerobic Pool", Icon: null },
  { code: "gymnasiumRoom", label: "Gymnasium Room", Icon: null },
  { code: "jacuzzi", label: "Jacuzzi", Icon: null },
  { code: "basementCarPark", label: "Basement Car Park", Icon: null },
  { code: "basketballCourt", label: "Basketball Court", Icon: null },
  { code: "bbqPits", label: "Bbq Pits", Icon: null },
  { code: "clubhouse", label: "Clubhouse", Icon: null },
  { code: "meetingRoom", label: "Meeting Room", Icon: null },
  { code: "swimmingPool", label: "Swimming Pool", Icon: null },
  { code: "coveredCarPark", label: "Covered Car Park", Icon: null },
  { code: "tennisCourts", label: "Tennis Courts", Icon: null },
  { code: "miniMart", label: "Mini Mart", Icon: null },
  { code: "wadingPool", label: "Wading Pool", Icon: null },
  { code: "fitnessCorner", label: "Fitness Corner", Icon: null },
  { code: "multiPurposeHall", label: "Multi Purpose Hall", Icon: null },
  { code: "funPool", label: "Fun Pool", Icon: null },
  { code: "garage", label: "Garage", Icon: null },
  { code: "penthouse", label: "Penthouse", Icon: null },
  { code: "swimmingPoolView", label: "Swimming Pool View", Icon: null },
  { code: "washingMachine", label: "Washing Machine", Icon: null },
  { code: "bed", label: "Bed", Icon: null },
  { code: "television", label: "Television", Icon: null },
  { code: "livingRoomFurniture", label: "Living Room Furniture", Icon: null },
  { code: "loadingBay", label: "Loading Bay", Icon: null },
  { code: "renovated", label: "Renovated", Icon: null },
  { code: "bathtub", label: "Bathtub", Icon: null },
  { code: "lapPool", label: "Lap Pool", Icon: null },
  { code: "lounge", label: "Lounge", Icon: null },
  { code: "maidsroom", label: "Maidsroom", Icon: null },
  { code: "seaView", label: "Sea View", Icon: null },
  { code: "kitchen", label: "Kitchen", Icon: null },
  { code: "walkInWardrobe", label: "Walk In Wardrobe", Icon: null },
  { code: "kitchenUtensils", label: "Kitchen Utensils", Icon: null },
  { code: "pavillion", label: "Pavillion", Icon: null },
  { code: "roofTerrace", label: "Roof Terrace", Icon: null },
  { code: "groundFloor", label: "Ground Floor", Icon: null },
  { code: "functionRoom", label: "Function Room", Icon: null },
  { code: "adventurePark", label: "Adventure Park", Icon: null },
  { code: "poolDeck", label: "Pool Deck", Icon: null },
  { code: "karaoke", label: "Karaoke", Icon: null },
  { code: "sauna", label: "Sauna", Icon: null },
  { code: "library", label: "Library", Icon: null },
  { code: "dryer", label: "Dryer", Icon: null },
  { code: "steamBath", label: "Steam Bath", Icon: null },
  { code: "serverRoom", label: "Server Room", Icon: null },
  { code: "cableTv", label: "Cable Tv", Icon: null },
  { code: "privateGarden", label: "Private Garden", Icon: null },
  { code: "ironIroningBoard", label: "Iron Ironing Board", Icon: null },
  { code: "largeFloorPlate", label: "Large Floor Plate", Icon: null },
  { code: "vacuumCleaner", label: "Vacuum Cleaner", Icon: null },
  { code: "puttingGreen", label: "Putting Green", Icon: null },
  { code: "spaPool", label: "Spa Pool", Icon: null },
  { code: "diningRoomFurniture", label: "Dining Room Furniture", Icon: null },
  { code: "launderette", label: "Launderette", Icon: null },
  { code: "openCarPark", label: "Open Car Park", Icon: null },
  { code: "hairdryer", label: "Hairdryer", Icon: null },
  { code: "avEquipment", label: "Av Equipment", Icon: null },
  { code: "dualPowerSource", label: "Dual Power Source", Icon: null },
  { code: "squashCourt", label: "Squash Court", Icon: null },
  { code: "badmintonHall", label: "Badminton Hall", Icon: null },
  { code: "miniGolfRange", label: "Mini Golf Range", Icon: null },
  { code: "drivingRange", label: "Driving Range", Icon: null },
  { code: "reflexologyPath", label: "Reflexology Path", Icon: null },
  { code: "skyLounge", label: "Sky Lounge", Icon: null },
  { code: "joggingTrack", label: "Jogging Track", Icon: null },
  { code: "terrace", label: "Terrace", Icon: null },
  { code: "colonialBuilding", label: "Colonial Building", Icon: null },
  { code: "privatePool", label: "Private Pool", Icon: null },
  { code: "ramUpAccess", label: "Ram Up Access", Icon: null },
  { code: "ownChillerPlant", label: "Own Chiller Plant", Icon: null },
  { code: "audioSystem", label: "Audio System", Icon: null },
  { code: "lakeView", label: "Lake View", Icon: null },
  { code: "amphitheatre", label: "Amphitheatre", Icon: null },
];

export const POI_TYPE = ["TRAIN", "SCHOOL", "BUS"];
export const STREET_POI_TYPE = "STREET";
export const DEFAULT_SEARCH_RECORD_LIMIT = 30;

export const FLOOR_NUMBER = Array(70)
  .fill()
  .map((_, index) => ({
    code: twoDigit(index + 1),
    label: twoDigit(index + 1),
  }));

export const APARTMENT_NUMBER = Array(100)
  .fill()
  .map((_, index) => ({
    code: twoDigit(index + 1),
    label: twoDigit(index + 1),
  }));
