import BrandConstants from "../brands/constants";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const weekDay = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const dateFormat = (date) => {
  var d = new Date(date),
    month = d.getMonth() + 1,
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (day.length < 2) day = "0" + day;

  return `${day} ${months[month]}`;
};

export const dateDetail = (d) => {
  let month = d.getMonth();
  let date = "" + d.getDate();
  let day = d.getDay();
  if (day.length < 2) day = "0" + day;

  return [
    date,
    weekDay[day],
    months[month],
    `${date}-${month}-${d.getFullYear()}`,
  ];
};

export const createSlugForListing = (listingData, propertyData) => {
  const { furnishing, rooms, listingType, bathrooms } = listingData;
  const { name } = propertyData;

  let slug = "";

  if (furnishing) {
    const val = BrandConstants.FURNISHING.find(
      (obj) => obj.code === furnishing
    );
    if (val && val.label) {
      slug += `${val.label.toLowerCase().split(" ").join("-")}`;
    }
  }

  if (rooms) {
    if (slug) {
      slug += "-";
    }
    slug += `${rooms}-bedroom`;
  }

  if (rooms) {
    slug += `-${bathrooms}-bathrooms`;
  }

  if (listingType) {
    slug += `-for-${listingType}`;
  }

  if (name) {
    const encodedName = encodeURIComponent(name).replace(
      /[~`!@#$%^&*()+={}\[\];:\'\"<>.,\/\\\?-_]/g,
      ""
    );
    slug += `-at-${encodedName.toLowerCase().split(" ").join("-")}`;
  }

  slug += `-${new Date().getTime()}`;

  return slug;
};

export const createSlugForProperty = (propertyData) => {
  const { name } = propertyData;

  let slug = "";

  if (name) {
    const encodedName = encodeURIComponent(name).replace(
      /[~`!@#$%^&*()+={}\[\];:\'\"<>.,\/\\\?-_]/g,
      ""
    );
    slug += `${encodedName.toLowerCase().split(" ").join("-")}`;
  }

  slug += `-${new Date().getTime()}`;

  return slug;
};

export const formatPrice = (price) => {
  return new Intl.NumberFormat("en-IN", { maximumSignificantDigits: 3 }).format(
    price
  );
};

export const meterToSquareFoot = (area) => {
  return Math.floor(area * 10.764);
};

export const perSquareFootCalc = (price, area) => {
  return parseFloat(price / meterToSquareFoot(area)).toFixed(2);
};

export const getDateByString = (str) => {
  // months
  const month = parseInt(str.substr(0, 2), 10);
  const year = str.substr(2, 4);

  return `${months[month - 1]} ${year}`;
};

export const loanCalculation = (loanAmount, interestRate, tenure) => {
  let interest = interestRate / 1200;
  let totalTenure = tenure * 12;
  let top = Math.pow(1 + interest, totalTenure);
  let bottom = top - 1;
  let ratio = top / bottom;
  let monthlyEMI = loanAmount * interest * ratio;
  let totalAmount = monthlyEMI * totalTenure;
  let interestAmount = totalAmount - loanAmount;

  totalAmount = Math.ceil(totalAmount.toFixed(2));
  interestAmount = Math.ceil(interestAmount.toFixed(2));
  loanAmount = Math.ceil(loanAmount);
  monthlyEMI = Math.ceil(monthlyEMI.toFixed(2));

  return {
    monthlyEMI,
    totalAmount,
    interestAmount,
    loanAmount,
  };
};

export const findFloorArea = (min, max, apartmentFloorNumber) => {
  // 16
  // 8
  // 30
  // 100
  // if (
  //   result.block &&
  //   result.block.unitNumbers &&
  //   result.block.unitNumbers.length &&
  //   result.unit
  // ) {
  //   const minFloor = 0;
  //   const maxFloor = 0;
  //   const unitFloorNumber = parseInt(result.unit.split("-")[0]);
  //   result.block.unitNumbers.forEach((unitNumber) => {
  //     const floorNumber = parseInt(unitNumber.floorNumber, 10);
  //     if (!minFloor) {
  //       minFloor = floorNumber;
  //     }
  //     if (!maxFloor) {
  //       maxFloor = floorNumber;
  //     }
  //     if (minFloor > floorNumber) {
  //       minFloor = floorNumber;
  //     }
  //     if (maxFloor < floorNumber) {
  //       maxFloor = floorNumber;
  //     }
  //   });
  //   // Mid calculation
  //   result.floorLevel = findFloorArea(
  //     minFloor,
  //     maxFloor,
  //     unitFloorNumber
  //   );
  // }
  // const mid = max / 2;
};

export const twoDigit = (number) => {
  return `${Number(number) < 10 ? "0" : ""}${number}`;
};
